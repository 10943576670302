export const ALERTS_REDUX_CONSTANTS = {
  GET_ALERT_LIST_SUCCESS: 'GET_ALERT_LIST_SUCCESS',
  RESET_ALERT_LIST_DATA: 'RESET_ALERT_LIST_DATA',
  INITIALIZE_FILTERS: 'INITIALIZE_FILTERS',
  INITIALIZE_ALERTS: 'INITIALIZE_ALERTS',

  GET_ALERT_COLUMN_LIST: 'GET_ALERT_COLUMN_LIST',
  GET_ALERT_DEFAULT_COLUMN_LIST: 'GET_ALERT_DEFAULT_COLUMN_LIST',
  UPDATE_ALERT_COLUMN_LIST: 'UPDATE_ALERT_COLUMN_LIST',

  GET_DROPDOWN_CLIENT_LIST: 'GET_DROPDOWN_CLIENT_LIST',
  GET_DROPDOWN_LIST_BY_SEARCH: 'GET_DROPDOWN_LIST_BY_SEARCH',
  GET_ALERT_FILTER_DROPDOWN_DATA: 'GET_ALERT_FILTER_DROPDOWN_DATA',

  UPDATE_ALERT_FILTER_FIELDS: 'UPDATE_ALERT_FILTER_FIELDS',
  APPLY_ALERT_FILTER_ACTION: 'APPLY_ALERT_FILTER_ACTION',
  CLOSE_ALERT_FILTER_ACTION: 'CLOSE_ALERT_FILTER_ACTION',

  RESET_ALERT_FILTER: 'RESET_ALERT_FILTER',

  GET_ALERT_DETAILS: 'GET_ALERT_DETAILS',
  CLEAR_ALERT_DETAILS: 'CLEAR_ALERT_DETAILS',

  SAVE_ALERT_ID: 'SAVE_ALERT_ID',
  REMOVE_ALERT_ID: 'REMOVE_ALERT_ID',
  UPDATE_ALERT_DETAILS_STATUS: 'UPDATE_ALERT_DETAILS_STATUS',
};
