export const DASHBOARD_REDUX_CONSTANTS = {
  DASHBOARD_DETAILS: 'DASHBOARD_DETAILS',
  RESET_DASHBOARD_DATA: 'RESET_DASHBOARD_DATA',

  TASK: {
    DASHBOARD_TASK_LIST_REQUEST: 'DASHBOARD_TASK_LIST_REQUEST',
    DASHBOARD_TASK_LIST_SUCCESS: 'DASHBOARD_TASK_LIST_SUCCESS',
    DASHBOARD_TASK_DETAILS: 'DASHBOARD_TASK_DETAILS',
    UPDATE_EDIT_TASK_FIELD_ACTION: 'UPDATE_EDIT_TASK_FIELD_ACTION',
  },
  NOTIFICATION: {
    DASHBOARD_NOTIFICATION_LIST_REQUEST: 'DASHBOARD_NOTIFICATION_LIST_REQUEST',
    DASHBOARD_NOTIFICATION_LIST_SUCCESS: 'DASHBOARD_NOTIFICATION_LIST_SUCCESS',
    DASHBOARD_NOTIFICATION_LIST_FAIL: 'DASHBOARD_NOTIFICATION_LIST_FAIL',
    DELETE_DASHBOARD_NOTIFICATION_ACTION: 'DELETE_DASHBOARD_NOTIFICATION_ACTION',
    CLEAR_NOTIFICATION_DATA: 'CLEAR_NOTIFICATION_DATA',
    GET_NOTIFICATION_FROM_SOCKET: 'GET_NOTIFICATION_FROM_SOCKET',
  },
};
