export const EMPLOYEE_REDUX_CONSTANTS = {
  EMPLOYEE_LIST_USER_ACTION: 'EMPLOYEE_LIST_USER_ACTION',
  SELECTED_EMPLOYEE_DATA: 'SELECTED_EMPLOYEE_DATA',
  RESET_EMPLOYEE_DETAILS: 'RESET_EMPLOYEE_DETAILS',
};
export const EMPLOYEE_COLUMN_LIST_REDUX_CONSTANTS = {
  EMPLOYEE_COLUMN_LIST_ACTION: 'EMPLOYEE_COLUMN_LIST_ACTION',
  EMPLOYEE_DEFAULT_COLUMN_LIST_ACTION: 'EMPLOYEE_DEFAULT_COLUMN_LIST_ACTION',
  UPDATE_EMPLOYEE_COLUMN_LIST_ACTION: 'UPDATE_EMPLOYEE_COLUMN_LIST_ACTION',
};
